import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { Modal, notification } from "antd";

import "./NewTicket.css";
import { createTicket, uploadTicketFile } from "../../../services/axios";
import { useSelector } from "react-redux";
import { UserRole } from "../../../common/common";

const NewTicket = ({ isShowModal, onClose, onSuccess, companies }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const user = useSelector((state) => state.auth.user);
  const [subject, setSubject] = useState("");
  const [company, setCompany] = useState("");
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setSubject("");
    setCompany("");
    setContent("");
    setFiles([]);
  }, [isShowModal]);

  const handleUploadFile = async (e) => {
    if (files.length >= 5) {
      notification.error({
        description: "Can't upload more than 5 files",
      });
      return;
    }

    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 20 * 1024 * 1024) {
        notification.error({
          description: "Can't upload file size is bigger than 20Mb.",
        });
        return;
      }

      const result = await uploadTicketFile(selectedFile);
      if (result.status == 200) {
        setFiles([
          ...files,
          {
            name: selectedFile.name,
            path: result.data.filePath
          }
        ]);
      }
    }
  }

  const handleRemoveFile = (e, index) => {
    e.stopPropagation();
    let newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }

  const handlSubmit = async () => {
    let msg = null;
    if (subject.length === 0) {
      msg = "Please input the subject";
    } else if (content.length === 0) {
      msg = "Please input the content";
    }
    if (msg != null) {
      notification.error({
        description: msg,
      });
      return;
    }

    const res = await createTicket(subject, company, content, files);
    if (res.status == 200) {
      notification.success({
        description: "New ticket saved successfully!",
      });
      onSuccess(res.data.result);
    } else {
      notification.error({
        description: "Something went wrong!",
      });
    }
  };

  return (
    <Modal
      title={<div className="text-center fw-bold" style={{ fontSize: "2rem", color: "#D01400" }}>New Ticket</div>}
      centered
      open={isShowModal}
      onOk={() => onClose()}
      onCancel={() => onClose()}
      width={600}
      style={{ maxWidth: "100%" }}
      className='ticket-edit-modal'
      footer={false}
    >
      <div className="new-ticket-main justify-content-center flex-column p-4">
        <div className="d-flex flex-column">
          <div className="sub1-subsub1-sub1-new-ticket-div1 d-flex flex-column">
            <label>Subject</label>
            <input
              className="w-100"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </div>
          {user.role == UserRole.Admin &&
            <div className="sub1-subsub1-sub1-new-ticket-div1 d-flex flex-column mt-4">
              <label>Company</label>
              <select
                className="w-100"
                onChange={(e) => setCompany(e.target.value)}
                value={company}
              >
                <option value=""></option>
                {companies && companies.map(item => <option key={item._id} value={item._id}>{item.name}</option>)}
              </select>
            </div>
          }
          <div className="sub2-subsub1-sub1-new-ticket-div1 d-flex flex-column mt-4">
            <label>Text</label>
            <textarea
              className="w-100"
              type="text"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
            />
          </div>
        </div>
        <div className={`d-flex w-100 my-5 justify-content-between ${isMobile && 'flex-column gap-5'}`}>
          <div className="d-flex flex-column">
            {files.map((item, index) => {
              return (
                <div key={index} className="sub1-left-subsub2-sub1-new-ticket-div1 position-relative my-2">
                  <a href={process.env.REACT_APP_URL + '/' + item.path} target="_blank">{item.name}</a>
                  <img src="/assets/redCross.svg" alt="none" onClick={(e) => handleRemoveFile(e, index)} />
                </div>
              );
            })}
          </div>
          <span className="sub1-center-subsub2-sub1-new-ticket-span">File size should Not be more than 20MB</span>
          <div className="sub1-right-subsub2-sub1-new-ticket-div1 position-relative cursor-pointer">
            <img className="" src="/assets/file.svg" alt="none" />
            <input className="position-absolute" type="file" onChange={handleUploadFile} />
          </div>
        </div>
        <div className="tab-button d-flex justify-content-center align-items-center mx-auto mt-5" onClick={() => handlSubmit()}>
          <img src="/assets/whiteSend.svg" alt="none" />
          <button className="ms-2">Submit</button>
        </div>
      </div>
    </Modal>
  );
};

export default NewTicket;
