import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { debounce } from "lodash";

import { updateUser, uploadAvatar } from "../../../services/axios";

import "./EditUser.css";
import { notification } from "antd";
import PhoneNumberInput from "../../commons/input/PhoneNumberInput";
import { UserRole } from "../../../common/common";
import { getCountries } from "../../../utils/globals";
import { UPDATE_USER } from "../../../redux/store/types";

const EditUser = () => {
  const user = useSelector((state) => state.auth.user);

  const location = useLocation();
  const getData = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.auth.user.role);
  const CountryData = getCountries();

  const [phoneNumber, setPhoneNumber] = useState(getData.state.mobile);
  const [suggestions, setSuggestions] = useState([]);
  const [avatarPath, setAvatarPath] = useState(getData.state.image);
  const [formData, setFormData] = useState({
    id: getData.state.id,
    address: getData.state.address,
    name: getData.state.name,
    username: getData.state.username,
    email: getData.state.email,
    mobile: getData.state.mobile,
    country: getData.state.country,
    company: getData.state.company,
    role: getData.state.role,
  })

  useEffect(() => {
    const func = debounce(async () => {
      let temp = [];
      if (formData.country) {
        let count = 0;
        let maxCount = 1000;
        let issame = false;
        for (let i = 0; i < CountryData.length; i++) {
          if ((CountryData[i].city + ", " + CountryData[i].country).toLowerCase() === formData.country.toLowerCase())
            issame = true;
          if ((CountryData[i].city + ", " + CountryData[i].country).toLowerCase().startsWith(formData.country.toLowerCase())) {
            temp.push({
              state: CountryData[i].city,
              country: CountryData[i].country,
            });
            count++;
          }
          if (count > maxCount) {
            break;
          }
        }
        if (issame) {
          setSuggestions([]);
        } else {
          setSuggestions(temp);
        }
      } else {
        setSuggestions([]);
      }

    }, 500);

    func();
    return () => {
      func.cancel();
    };
  }, [formData.country])

  const handleNumber = (e) => {
    setPhoneNumber(e)
  };

  const handleData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = () => {
    let msg = null;
    if (formData.name.length === 0) {
      msg = "Please input your name!";
    } else if (formData.username.length === 0) {
      msg = "Please input your username!";
    } else if (formData.email.length === 0) {
      msg = "Please input your email!";
    } else if (phoneNumber.length === 0) {
      msg = "Please input your mobile number!";
    } else if (formData.country.length === 0) {
      msg = "Please input your country";
    } else if (formData.address.length === 0) {
      msg = "Please input your address!";
    } else if (formData.role.length === 0) {
      msg = "Please select your role!";
    } else if (formData.company?.length === 0) {
      msg = "Please select your company!";
    }
    if (msg != null) {
      notification.warning({
        placement: "topRight",
        description: msg,
        duration: 3,
      });
      return;
    }
    final()
  }

  const final = async () => {
    const sendData = {
      id: formData.id,
      fname: formData.name,
      lname: formData.username,
      email: formData.email,
      phone: phoneNumber,
      country: formData.country,
      address: formData.address,
      role: formData.role,
      company: formData.company,
      image: avatarPath
    }
    var res = await updateUser(sendData);

    if (res?.status == 200) {
      notification.success({
        placement: "topRight",
        description: "User has been updated successfully!",
        duration: 3,
      });

      if (user.lname == formData.username) {
        dispatch({
          type: UPDATE_USER,
          payload: sendData
        });
      }

      setTimeout(() => {
        navigate("/Users");
      }, 1000);
    }
    else {
      notification.error({
        placement: "topRight",
        description: res?.data.message,
        duration: 3,
      });
    }
  }

  const onChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      const result = await uploadAvatar(selectedFile);
      if (result.status == 200) {
        setAvatarPath(result.data.filePath);
      }
    }
  }

  return (
    <div className="edit-user-main">
      <p>Edit User</p>
      <div className='edit-user-div1 d-flex justify-content-start flex-column p-5 mx-5'>
        <div className='person-edit-user-sub1-edit-user-div1 d-flex justify-content-center position-relative mb-5'>
          <input
            type='file'
            className='personfile position-absolute'
            accept='image/*'
            onChange={onChange}
          />
          <img
            crossOrigin='*'
            src={avatarPath ? process.env.REACT_APP_URL + '/' + avatarPath : '/assets/common_user.png'}
            alt="none"
            className="person-edit-user object-fit-cover"
          />
        </div>
        <div className='edit-input-container d-flex justify-content-evenly mb-5'>
          <div className='d-flex flex-column'>
            <label>Name</label>
            <input className='normal-input' value={formData.name} type='text' name="name" onChange={handleData} />
          </div>
          <div className="sub2-subsub1-sub1-edit-user-div1 d-flex flex-column position-relative">
            <label>Username</label>
            <input className='normal-input' value={formData.username} type='text' name="username" onChange={handleData} disabled />
          </div>
        </div>
        <div className='edit-input-container d-flex justify-content-evenly mb-5'>
          <div className='d-flex flex-column'>
            <label>Email</label>
            <input className='normal-input' value={formData.email} type='email' name="email" onChange={handleData} />
          </div>
          <div className='d-flex flex-column'>
            <label>Mobile No.</label>
            <div className='input-item'>
              <PhoneNumberInput
                value={phoneNumber}
                onChange={handleNumber}
              />
            </div>
          </div>
        </div>
        <div className='edit-input-container d-flex justify-content-evenly mb-5'>
          <div className='d-flex flex-column position-relative'>
            <label>Country & City</label>
            <input className='normal-input' name='country' type='text' value={formData.country} onChange={handleData} required />
            {formData.country && (
              <div className='suggestion position-absolute w-100 px-3 overflow-y-auto bg-white'>
                {suggestions.map((cityData, index) => (
                  <p
                    className='my-1 cursor-pointer p-0'
                    key={index}
                    onClick={() => {
                      setSuggestions([]);
                      setFormData({ ...formData, "country": cityData.state + ", " + cityData.country });
                    }}
                  >
                    {cityData.state + ", " + cityData.country}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div className='d-flex flex-column'>
            <label>Address</label>
            <input className='normal-input' type='text' value={formData.address} name="address" onChange={handleData} />
          </div>
        </div>
        <div className='edit-input-container d-flex justify-content-evenly mb-5'>
          <div className='d-flex flex-column'>
            <label>User Type</label>
            <select className='normal-input' type='text' name="role" onChange={handleData} value={formData.role} >
              {role === UserRole.Admin && <option value={UserRole.Admin}>Super Admin</option>}
              {(role == UserRole.Admin || role == UserRole.Manager) && <option value={UserRole.Manager}>Admin</option>}
              <option value={UserRole.Client}>Client</option>
            </select>
          </div>
          <div className='d-flex flex-column'>
            <label>Company</label>
            <select className='normal-input' type='text' name="company" value={formData.company} onChange={handleData} >
              <option value=""></option>
              {location?.state?.companies && location?.state?.companies.map(item => <option key={item._id} value={item._id}>{item.name}</option>)}
            </select>
          </div>
        </div>
        <div className="subsub5-sub1-edit-user-div1 d-flex justify-content-center">
          <button className='me-5' onClick={() => navigate("/Users")}>Cancel</button>
          <button onClick={() => handleSubmit()}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
