import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SystemParams from "./system params/SystemParams";
import InputOutput from "./input-output/InputOutput";
import Connectivity from "./connectivity/Connectivity";
import Configuration from "./configuration/Configuration";
import Tracking from "./tracking/Tracking"
import Miscellaneous from "./miscellaneous/Miscellaneous"
import Bluetooth from "./bluetooth/Bluetooth"
import Can from "./can/Can";
import Actions from "./actions/Actions";
import CommandsQueue from "./commands-queue/CommandsQueue";
import Accelerometer from "./accelerometer/Accelerometer";

import "./CommandsSettings.css";
import { showConfirmEx } from "../../utils/globals";
import { commandSettingAll } from "../../services/axios";
import { message } from "antd";
import { sendBatchCommandMsg } from "../../socket";

const tabs = [
  {
    id: 0,
    label: "Input / Output",
    component: <InputOutput />
  },
  {
    id: 1,
    label: "System Params",
    component: <SystemParams />
  },
  {
    id: 2,
    label: "Connectivity",
    component: <Connectivity />
  },
  {
    id: 3,
    label: "SMS / Call Settings",
    component: <Configuration />
  },
  {
    id: 4,
    label: "Tracking",
    component: <Tracking />
  },
  {
    id: 5,
    label: "Features",
    component: <Miscellaneous />
  },
  {
    id: 6,
    label: "Accelerometer",
    component: <Accelerometer />
  },
  {
    id: 7,
    label: "CAN",
    component: <Can />
  },
  {
    id: 8,
    label: "Bluetooth",
    component: <Bluetooth />
  },
  {
    id: 9,
    label: "Actions",
    component: <Actions />
  },
  {
    id: 10,
    label: "Commands Queue",
    component: <CommandsQueue />
  },
]

const CommandsSettings = () => {

  const getData = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(0);

  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);


  const data = {
    Name: getData?.state?.Name,
    device: getData?.state?.device,
    deviceImei: getData?.state?.deviceImei,
  }

  useEffect(() => {
    let tab = searchParams.get("tab");
    if (!tab) {
      tab = 0;
    }
    setSelectedTab(parseInt(tab));
  }, [searchParams]);

  useEffect(() => {
    if (isLoading) {
      messageApi.open({
        type: 'loading',
        content: 'Sending Refresh Commands ...',
        duration: 0,
      });
    } else {
      messageApi.destroy();
    }
  }, [isLoading]);

  const handleSelectTab = (tabId) => {
    setSelectedTab(tabId);
    setSearchParams({ "tab": tabId }, getData);
  }

  const handleRefreshAll = () => {
    showConfirmEx(`Are you sure to refresh all settings`)
      .then(async () => {
        setIsLoading(true);
        const res = await commandSettingAll({ devImei: data.deviceImei });
        if (res.status == 200) {
          sendBatchCommandMsg(data.deviceImei);
        }
        setIsLoading(false);
      })
      .catch(() => {
      });
  }


  return (
    <div className="command-setting-main px-4 py-5">
      {contextHolder}

      <div className="sub1-command-setting-div1 d-flex justify-content-center align-items-center py-1">
        <p className="text-center mb-0 text-white">Commands & Settings</p>
      </div>
      <div className="command-setting-div2 w-100 d-flex align-items-center my-4">
        <div className="sub1-command-setting-div2 d-flex flex-column justify-content-evenly p-3 text-white">
          <p className="mb-0" style={{ color: "#28A745", fontSize: "1.3rem", fontFamily: "rbold" }}>{data.Name}</p>
          <p className="mb-0">
            <span style={{ color: "#28A745" }}>Device: </span>
            <span className="fst-italic">{data.device}</span>
          </p>
          <p className="mb-0">
            <span style={{ color: "#28A745" }}>IMEI: </span>
            <span className="fst-italic">{data.deviceImei}</span>
          </p>
        </div>
        <div className="sub2-command-setting-div2 d-flex justify-content-between w-100 gap-3 px-3">
          <div
            className="command d-flex justify-content-center align-items-center cursor-pointer active"
            style={{ minWidth: "3rem", backgroundColor: "#005EEC", fontSize: "1.4rem", flexGrow: 0 }}
            onClick={handleRefreshAll}
          >
            <FontAwesomeIcon className="white" icon={faRotate} />
          </div>
          {tabs.map((tab) => (
            <div key={tab.id} className={`command d-flex justify-content-center align-items-center cursor-pointer ${selectedTab == tab.id && 'active'}`} onClick={() => handleSelectTab(tab.id)}>
              <p>{tab.label}</p>
            </div>
          ))}
        </div>
      </div>
      {tabs.filter(tab => tab.id === selectedTab)[0].component}
    </div>
  );
};

export default CommandsSettings;
