import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import "./SideBar.css";
import { formatDateTime, getDeviceImage, showConfirmEx } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";


const ControlPanelAC = ({ selectedVehData, hidePanel, setLoadingMsg, setIsLoading, setKeepControlPanel }) => {
  const navigate = useNavigate();

  const handleLock = (checked) => {
    if (!selectedVehData?.deviceImei) {
      return;
    }

    setKeepControlPanel(true);
    showConfirmEx(`Are you sure to ${checked ? 'Lock' : 'Unlock'} Door?`)
      .then(async () => {
        setLoadingMsg("Toggling Door Lock status ...");
        setKeepControlPanel(false);
        setIsLoading(true);

        sendCommandMsg(null, selectedVehData?.deviceImei, `32:01${checked ? '01' : '00'}`);
      }).catch(() => {
        setKeepControlPanel(false);
      });
  };

  return (
    <div className="position-relative">
      <img
        className="car-close-portal-side-bar"
        src="/assets/close.svg"
        alt="none"
        onClick={hidePanel}
      />
      <div className="car-portal-side-bar d-flex justify-content-center align-items-center">
        <div className="sub1-car-portal-side-bar bg-white">
          <div className="subsub1-sub1-car-portal-side-bar d-flex px-4 py-3 w-100 align-items-center justify-content-start gap-3">
            <img crossOrigin="*" src={selectedVehData?.company ? process.env.REACT_APP_URL + '/' + selectedVehData?.company.logo : "/assets/uber.png"} alt="none" />
            <p className="mb-0">{selectedVehData?.company?.name ?? "Uber"}</p>
          </div>
          <div className="subsub2-sub1-car-portal-side-bar d-flex align-items-center my-1 py-4 px-4">
            <img src={`/assets/${selectedVehData?.vehicleType}.svg`} alt="none" style={{ width: "5.8rem", maxHeight: "4rem" }} />
            <p className="ms-4 mb-0">
              {selectedVehData?.vehicleName}
            </p>
          </div>
          <div className="subsub3-sub1-car-portal-side-bar my-1 py-3 px-4">
            <div className="sub1-subsub3-sub1-car-portal-side-bar d-flex align-items-center justify-content-center">
              <img src="/assets/arrow.png" alt="none" />
              <p className="mb-0 ms-5" style={{ width: "11rem" }}>
                <div style={{ color: selectedVehData?.connected ? '#28A745' : '#FF0000' }}>
                  {selectedVehData?.connected ? 'Connected' : 'Not Connected'}&nbsp;
                </div>
                <span>{formatDateTime(selectedVehData?.transferDate)}&nbsp;</span>
              </p>
            </div>
          </div>
          <div className={`subsub5-sub1-car-portal-side-bar ${selectedVehData?.vehicleType}`}>
            <img src={getDeviceImage(selectedVehData?.deviceModel)} alt="none" />
          </div>
          <div className="subsub6-sub1-car-portal-side-bar d-flex justify-content-center align-items-center">
            <p className="mb-0 py-1">
              MAC: {selectedVehData?.deviceImei}
            </p>
          </div>
        </div>

        <div className="sub2-car-portal-side-bar h-100 d-flex flex-column justify-content-between align-items-center px-4 py-5">
          <div className="subsub1-sub2-car-portal-side-bar w-100 d-flex">
            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
              onClick={() => {
                navigate('/acs/TerminalStatus', {
                  state: selectedVehData
                });
                hidePanel();
              }}
            >
              <img src="/assets/terminal_status.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">
                Terminal Status
              </p>
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer">
              <img src={`/assets/door_status.svg`} alt="none" />
              <p className="mt-4 mb-0 text-white">
                Door <span className={`engine-status ${selectedVehData?.ignition == 1 ? 'ON' : 'OFF'}`}>
                  {selectedVehData?.ignition == 1 ? 'Open' : 'Closed'}
                </span>
              </p>
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer">
              <img src="/assets/fingerprint_sync.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">
                Fingerprint Sync
              </p>
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
              onClick={() => {
                navigate('/acs/UserList', {
                  state: selectedVehData
                });
                hidePanel();
              }}
            >
              <img src="/assets/users.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">
                Users
              </p>
            </div>
          </div>
          <div className="horizontal-bar w-100"></div>
          <div className="subsub1-sub2-car-portal-side-bar w-100 d-flex">
            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer position-relative"
              onClick={() => {
                navigate('/acs/Groups', {
                  state: selectedVehData
                });
                hidePanel();
              }}
            >
              <img src="/assets/groups.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">
                Groups
              </p>
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
              onClick={() => {
                navigate('/acs/TerminalSetup', {
                  state: selectedVehData
                });
                hidePanel();
              }}
            >
              <img src="/assets/terminal_setup.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">
                Terminal Setup
              </p>
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
              onClick={() => {
                navigate('/acs/SystemLogs', {
                  state: selectedVehData
                });
                hidePanel();
              }}
            >
              <img src="/assets/system_logs.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">
                System Logs
              </p>
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
              onClick={() => {
                navigate('/acs/AccessLogs', {
                  state: selectedVehData
                });
                hidePanel();
              }}
            >
              <img src="/assets/access_logs.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">Access Logs</p>
            </div>
          </div>
          <div className="horizontal-bar w-100"></div>
          <div className="subsub1-sub2-car-portal-side-bar w-100 d-flex">
            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer">
              <img src={selectedVehData?.securityStatus?.startsWith("01") ? "/assets/door_lock.svg" : "/assets/door_lock.svg"} alt="none" />
              <label className="car-switch door mt-4">
                <input
                  type="checkbox"
                  checked={selectedVehData?.securityStatus?.startsWith("01")}
                  onChange={(e) => handleLock(e.target.checked)}
                />
                <span className="car-slider">
                  <div className="car-on-off">
                    <p className="car-p car-p-on" style={{ marginRight: "0" }}>LOCK</p>
                    <p className="car-p car-p-off" style={{ marginRight: "1.8rem" }}>UNLOCK</p>
                  </div>
                </span>
              </label>
            </div>

            <div
              className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
              onClick={() => { }}
            >
              <img src="/assets/Pic9.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">Access Schedule</p>
            </div>

            <div
              className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
              onClick={() => { }}
            >
              <img src="/assets/commands.svg" alt="none" />
              <p className="text-center text-white mt-4 mb-0">Door Setting</p>
            </div>

            <div
              className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
            >
              <img src="/assets/reboot.svg" alt="none" />
              <p className="text-center text-white mt-4 mb-0">Reboot</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlPanelAC;
