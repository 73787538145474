import React, { useEffect, useState } from "react";

import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./acs.css";
import { useLocation } from "react-router-dom";
import { Checkbox, notification, Pagination, Tooltip } from "antd";
import { sendCommandMsg, socket } from "../../socket";
import { getBytesFromString, getDateValueStr, getPacketIntValue, getValueStr, showConfirmEx, sleep } from "../../utils/globals";
import { ACSUserTypes, ACSVerifyModes } from "../../common/common";
import { getACSUserList, deleteACSUsers, upsertACSUser } from "../../services/axios";

import UserEdit from "./UserEdit";
import UserImport from "./UserImport";
import LoadingSpinner from "../commons/loading/LoadingSpinner";

const UserDeleteResult = {
  '00': 'User deletion was successful',
  '02': 'Unknown error has occurred',
  '03': 'Not a registered user',
  '04': 'Check sum error',
  '05': 'Other packet error',
  '08': 'Unknown command',
};

const UserList = ({ }) => {

  const location = useLocation();
  const selectedVehData = location.state;

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [checkedList, setCheckedList] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  const [searchName, setSearchName] = useState("");
  const [searchID, setSearchID] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (selectedVehData) {
      loadPage();
    }

    const onUpdateCommandResponse = async (cmdData) => {
      console.log("🚀 ~ onUpdateCommandResponse ~ cmdData:", cmdData)

      const { deviceImei, matchingId, response, result } = cmdData;
      if (deviceImei != selectedVehData.deviceImei) {
        return;
      }

      if (matchingId == "01") {
        // user delete 

        if (result == '00') {
          let userId = getPacketIntValue(response, 0, 4);
          // let item = dataList.find(item => item.userId == userId);
          // if (item) {
          //   await deleteACSUsers([item._id]);
          // }
          // setDataList(dataList.filter(item => item.userId != userId));
          notification.success({ description: UserDeleteResult['00'] });
        } else {
          let msg = UserDeleteResult[result];
          if (!msg) { msg = `Unknown Error : ${result}!`; }
          notification.error({ description: msg });
        }
      } else if (matchingId == '07') {
        // user status update

        if (result == '00') {
          notification.success({ description: "Succeed!" });

          let userId = getPacketIntValue(response, 0, 4);
          let item = dataList.find(item => item.userId == userId);
          if (item) {
            await upsertACSUser({
              device: selectedVehData.deviceImei,
              userId: userId,
              status: 1 - item.status
            });
            setDataList(dataList.map(item => (
              item.userId != userId ? item : { ...item, status: 1 - item.status }
            )));
          }

        } else {
          notification.error({ description: "Error occured!" });
        }
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);

    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, [selectedVehData]);

  useEffect(() => {
    let allChecked = true;
    for (const item of dataList) {
      if (!checkedList?.find(uid => uid == item.userId)) {
        allChecked = false;
        break;
      }
    }
    setCheckedAll(allChecked);
  }, [checkedList, dataList]);

  const handleCheck = (checked, item) => {
    const tempList = [...checkedList];
    const index = tempList.findIndex(uid => uid == item.userId);
    if (index != -1) {
      tempList.splice(index, 1);
    }
    if (checked) {
      tempList.push(item.userId);
    }
    setCheckedList(tempList);
  }
  const handleCheckAll = (checked) => {
    if (checked) {
      setCheckedList(dataList?.map(item => item.userId));
    } else {
      setCheckedList([]);
    }
    setCheckedAll(checked);
  }

  const loadPage = async (pageNum = 1) => {
    setIsLoading(true);
    const res = await getACSUserList({
      device: selectedVehData.deviceImei,
      pageNum: pageNum, pageSize: 10,
      userName: searchName,
      userId: searchID,
      status: searchStatus
    });
    if (res.status == 200) {
      setDataList(res.data.result);
      setTotalCount(res.data.totalCount);
      setPage(pageNum);
    } else {
      setDataList([]);
      setTotalCount(0);
      setPage(1);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    loadPage(1);
  }, [searchName, searchID, searchStatus]);

  const handleClear = () => {
    setSearchName("");
    setSearchID("");
    setSearchStatus("");
  }

  const handlePageChange = async (pageNum) => {
    loadPage(pageNum);
  }

  const handleAdd = async () => {
    setIsEdit(true);
    setSelectedUser(null);
  }
  const handleImport = async () => {
    setIsImport(true);
  }
  const handleSync = async () => {
  }

  const handleActivate = async (item) => {
    showConfirmEx(`Are you sure to proceed?`)
      .then(async () => {
        const result = [
          getValueStr(item.userId, 4),
          getValueStr(1, 1),      // 0 - don't overwrite, 1 - overwrite
          getValueStr(item.cardNo, 8),
          getValueStr(0, 10),
          getBytesFromString(item.userName, 31),
          getValueStr(item.expireStatus, 1),
          getDateValueStr(item.expireStart),
          getDateValueStr(item.expireEnd),
          getValueStr(1 - item.status, 1),
          getValueStr(item.type, 1),
          getValueStr(1, 1),        // 1 - free group, 0 - disallowed group
          getValueStr(0, 3),        // 1 - free group, 0 - disallowed group
          getValueStr(item.verifyMode, 1),
          getValueStr(0, 20),
          getValueStr(item.fpCount, 1),
          getValueStr(item.fpTempSize, 2),
          item.fpTempData,
        ];
        sendCommandMsg(null, selectedVehData?.deviceImei, "07:" + result.join(""));
      })
      .catch((err) => { });
  }
  const handleEdit = async (item) => {
    setIsEdit(true);
    setSelectedUser(item);
  }

  const handleRemoveSel = async () => {
    showConfirmEx(`Are you sure to remove?`)
      .then(async () => {
        setIsLoading(true);
        const ids = [];
        for (const uid of checkedList) {
          ids.push(dataList.find(item => item.userId == uid)._id);
          sendCommandMsg(null, selectedVehData.deviceImei, "01:" + getValueStr(uid, 4));
          await sleep(200);
        }
        await deleteACSUsers(ids);
        setIsLoading(false);
        loadPage();
      })
      .catch((err) => { });
  }
  const handleRemove = async (item) => {
    showConfirmEx(`Are you sure to remove?`)
      .then(async () => {
        sendCommandMsg(null, selectedVehData.deviceImei, "01:" + getValueStr(item.userId, 4));
        await deleteACSUsers([item._id]);
        loadPage(page);
      })
      .catch((err) => { });
  }

  return (
    <>
      <LoadingSpinner visible={isLoading} global={false} />

      <div className={`acs-panel`} style={{ display: (isEdit || isImport) ? 'none' : 'block' }}>
        <div className="panel-title d-flex justify-content-between">
          <div>{selectedVehData.vehicleName} {'>'} Users List</div>

          <div className="d-flex gap-3">
            {checkedList.length > 0 &&
              <button className="tab-button" onClick={handleRemoveSel}>
                <img src="/assets/delete.svg" alt="none" />
                <span className="ms-2">Delete</span>
              </button>
            }
            <button className="tab-button" onClick={handleImport} style={{ backgroundColor: "#1E6B97" }}>
              <img src="/assets/import.svg" alt="none" />
              <span className="ms-2">Import</span>
            </button>
            <button className="tab-button" onClick={handleSync} style={{ backgroundColor: "#F8971D" }}>
              <img src="/assets/sync.svg" alt="none" />
              <span className="ms-2">Sync User</span>
            </button>
            <button className="tab-button" onClick={handleAdd}>
              <img src="/assets/addbtn.svg" alt="none" />
              <span className="ms-2">Add User</span>
            </button>
          </div>
        </div>

        <div className="panel-body">
          <div className="subsub1-sub2-div1 py-2 mb-4">
            <div className="sub1-subsub1-sub2-div1 my-2">
              <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
              <span>Search User</span>
            </div>
            <div className="sub2-subsub1-sub2-div1">
              <input
                className="field-input"
                type="text"
                placeholder="Name"
                value={searchName}
                onChange={e => setSearchName(e.target.value)}
              />
              <div style={{ width: "50%" }}></div>
              <input
                className="field-input"
                type="numder"
                placeholder="ID"
                value={searchID}
                onChange={e => setSearchID(e.target.value)}
              />
              <div style={{ width: "50%" }}></div>
              <select
                className="field-select"
                value={searchStatus}
                onChange={e => setSearchStatus(e.target.value)}
              >
                <option value="">Status</option>
                <option value={1}>Active</option>
                <option value={0}>Deactivateed</option>
              </select>
              <div style={{ width: "50%" }}></div>
              <div
                className="tab-button d-flex justify-content-center align-items-center px-4 ms-4"
                onClick={() => handleClear()}
              >
                <img src="/assets/clear.svg" alt="none" />
                <button>Clear</button>
              </div>
            </div>
          </div>

          <div className="info-area w-100 userlist mb-3">
            <div className="table-header mb-2">
              <div>
                <Checkbox
                  checked={checkedAll}
                  onChange={(e) => handleCheckAll(e.target.checked)}
                />
              </div>
              <div>Name</div>
              <div>ID</div>
              <div>User Type</div>
              <div>Verify Mode</div>
              <div>Status</div>
              <div>Credentials</div>
              <div>&nbsp;</div>
            </div>
            <div className="table-body d-flex flex-column gap-2 overflow-auto">
              {dataList.map((item, index) =>
                <div key={index}>
                  <div>
                    <Checkbox
                      checked={checkedList.includes(item?.userId)}
                      onChange={(e) => handleCheck(e.target.checked, item)}
                    />
                  </div>
                  <div>{item.userName}</div>
                  <div>{item.userId}</div>
                  <div>{ACSUserTypes[item.type]}</div>
                  <div>{ACSVerifyModes[item.verifyMode]}</div>
                  <div className={`${item.status ? 'green-black' : 'red-black'}`}>{item.status ? "Actived" : "Deactived"}</div>
                  <div>
                    {item.fpCount > 0 &&
                      <>
                        <img src="/assets/fingerprint.svg" alt="none" className="me-2" /> <span className="me-4 red-black">{item.fpCount}</span>
                      </>
                    }
                    {item.cardNo > 0 &&
                      <>
                        <Tooltip title={`${item.cardNo}`} placement="bottom">
                          <img src="/assets/acscard.svg" alt="none" className="me-2" /> <span className="red-black">1</span>
                        </Tooltip>
                      </>
                    }
                  </div>
                  <div className="justify-content-center position-relative">
                    <FontAwesomeIcon
                      className="dropdown-toggle devices-dropdown-img"
                      icon={faEllipsisVertical}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    />
                    <div className="dropdown-menu dropdown-menu-end devices-dropdown-div">
                      <div className="sub1--devices-dropdown-div mx-3 d-flex flex-column justify-content-evenly h-100">
                        <div
                          className="d-flex align-items-center cursor-pointer action-item"
                          onClick={() => handleActivate(item)}
                        >
                          <img src={item.status ? "/assets/deactivate.svg" : "/assets/Activate.svg"} alt="none" />
                          <p className="mb-0 ms-3">{item.status ? "Deactivate" : "Active"}</p>
                        </div>
                        <div
                          className="d-flex align-items-center cursor-pointer action-item"
                          onClick={() => handleEdit(item)}
                        >
                          <img src="/assets/edit.svg" alt="none" />
                          <p className="mb-0 ms-3">Edit</p>
                        </div>
                        <div
                          className="d-flex align-items-center cursor-pointer action-item"
                          onClick={() => handleRemove(item)}
                        >
                          <img src="/assets/remove.svg" alt="none" />
                          <p className="mb-0 ms-3">Remove</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="text-center pt-3">
            <Pagination
              size="small"
              total={totalCount}
              current={page}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      {isEdit &&
        <UserEdit
          selectedVehData={selectedVehData}
          selectedUser={selectedUser}
          onClose={() => {
            setIsEdit(false);
            loadPage(page);
          }}
        />
      }
      {isImport &&
        <UserImport
          selectedVehData={selectedVehData}
          selectedUser={selectedUser}
          onClose={() => {
            setIsImport(false);
            loadPage(page);
          }}
        />
      }
    </>
  );
};

export default UserList;
