import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import { updateMe, updateUser, uploadAvatar } from "../../../services/axios";

import { Input, notification } from "antd";
import PhoneNumberInput from "../../commons/input/PhoneNumberInput";
import { getCountries } from "../../../utils/globals";
import { UPDATE_USER } from "../../../redux/store/types";
import { useNavigate } from "react-router-dom";

const EditMe = () => {
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const CountryData = getCountries();

  const [phoneNumber, setPhoneNumber] = useState(user.phone);
  const [suggestions, setSuggestions] = useState([]);
  const [avatarPath, setAvatarPath] = useState(user.image);
  const [formData, setFormData] = useState({
    address: user.address,
    name: user.fname,
    username: user.lname,
    email: user.email,
    country: user.country,
    passwd: "",
    cfmpasswd: ""
  })

  useEffect(() => {
    const func = debounce(async () => {
      let temp = [];
      if (formData.country) {
        let count = 0;
        let maxCount = 1000;
        let issame = false;
        for (let i = 0; i < CountryData.length; i++) {
          if ((CountryData[i].city + ", " + CountryData[i].country).toLowerCase() === formData.country.toLowerCase())
            issame = true;
          if ((CountryData[i].city + ", " + CountryData[i].country).toLowerCase().startsWith(formData.country.toLowerCase())) {
            temp.push({
              state: CountryData[i].city,
              country: CountryData[i].country,
            });
            count++;
          }
          if (count > maxCount) {
            break;
          }
        }
        if (issame) {
          setSuggestions([]);
        } else {
          setSuggestions(temp);
        }
      } else {
        setSuggestions([]);
      }

    }, 500);

    func();
    return () => {
      func.cancel();
    };
  }, [formData.country])

  const handleNumber = (e) => {
    setPhoneNumber(e)
  };

  const handleData = (e, name) => {
    setFormData({ ...formData, [name]: e.target.value });
  }

  const handleSubmit = () => {
    let msg = null;
    if (formData.name.length === 0) {
      msg = "Please input your name!";
    } else if (formData.username.length === 0) {
      msg = "Please input your username!";
    } else if (formData.email.length === 0) {
      msg = "Please input your email!";
    } else if (phoneNumber.length === 0) {
      msg = "Please input your mobile number!";
    } else if (formData.country.length === 0) {
      msg = "Please input your country";
    } else if (formData.passwd != formData.cfmpasswd) {
      msg = "Password not equal!";
    }

    if (msg != null) {
      notification.warning({
        description: msg,
      });
      return;
    }
    final()
  }

  const final = async () => {
    const sendData = {
      fname: formData.name,
      lname: formData.username,
      email: formData.email,
      phone: phoneNumber,
      country: formData.country,
      address: formData.address,
      image: avatarPath,
      passwd: formData.passwd
    }
    var res = await updateMe(sendData);

    if (res?.status == 200) {
      notification.success({
        description: res.data.message,
      });

      dispatch({
        type: UPDATE_USER,
        payload: res.data.result
      });

      navigate("/Dashboard");
    }
    else {
      notification.error({
        description: res.data.message,
      });
    }
  }

  const onChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      const result = await uploadAvatar(selectedFile);
      if (result.status == 200) {
        setAvatarPath(result.data.filePath);
      }
    }
  }

  return (
    <div className="edit-user-main">
      <p>Update My Account</p>
      <div className='edit-user-div1 d-flex justify-content-center flex-column p-5 mx-5'>
        <div className='person-edit-user-sub1-edit-user-div1 d-flex justify-content-center position-relative mb-5'>
          <input
            type='file'
            className='personfile position-absolute'
            accept='image/*'
            onChange={onChange}
          />
          <img
            crossOrigin='*'
            src={avatarPath ? process.env.REACT_APP_URL + '/' + avatarPath : '/assets/common_user.png'}
            alt="none"
            className="person-edit-user object-fit-cover"
          />
        </div>
        <div className='edit-input-container d-flex justify-content-evenly mb-5'>
          <div className='d-flex flex-column'>
            <label>Name</label>
            <input className='normal-input' value={formData.name} type='text' onChange={e => handleData(e, 'name')} />
          </div>
          <div className="sub2-subsub1-sub1-edit-user-div1 d-flex flex-column position-relative">
            <label>Username</label>
            <input className='normal-input' value={formData.username} type='text' onChange={e => handleData(e, 'username')} disabled />
          </div>
        </div>
        <div className='edit-input-container d-flex justify-content-evenly mb-5'>
          <div className='d-flex flex-column'>
            <label>Email</label>
            <input className='normal-input' value={formData.email} type='email' onChange={e => handleData(e, 'email')} />
          </div>
          <div className='d-flex flex-column'>
            <label>Mobile No.</label>
            <div className='input-item'>
              <PhoneNumberInput
                value={phoneNumber}
                onChange={handleNumber}
              />
            </div>
          </div>
        </div>
        <div className='edit-input-container d-flex justify-content-evenly mb-5'>
          <div className='d-flex flex-column position-relative'>
            <label>Country & City</label>
            <input className='normal-input' type='text' value={formData.country} onChange={e => handleData(e, 'country')} required />
            {formData.country && (
              <div className='suggestion position-absolute w-100 px-3 overflow-y-auto bg-white'>
                {suggestions.map((cityData, index) => (
                  <p
                    className='my-1 cursor-pointer p-0'
                    key={index}
                    onClick={() => {
                      setSuggestions([]);
                      setFormData({ ...formData, "country": cityData.state + ", " + cityData.country });
                    }}
                  >
                    {cityData.state + ", " + cityData.country}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div className='d-flex flex-column'>
            <label>Address</label>
            <input className='normal-input' type='text' value={formData.address} onChange={e => handleData(e, 'address')} />
          </div>
        </div>
        <div className='edit-input-container d-flex justify-content-evenly mb-5'>
          <div className='d-flex flex-column'>
            <label>User Type</label>
            <select className='normal-input' type='text' name="role" onChange={() => { }} value={user.role} >
              <option value={user.role}>{user.role}</option>
            </select>
          </div>
          <div className='d-flex flex-column'>
            <label>Company</label>
            <select className='normal-input' type='text' name="company" value={user.company?._id} onChange={() => { }} >
              <option value={user.company?._id}>{user.company?.name}</option>
            </select>
          </div>
        </div>
        <div className='edit-input-container d-flex justify-content-evenly mb-3 gap-3'>
          <div className='d-flex flex-column'>
            <label>Password</label>
            <Input.Password size='large' name='password-4-account' className='normal-input' value={formData.passwd} onChange={e => handleData(e, 'passwd')} autoComplete='new-passwd3' />
          </div>
          <div className='sub2-subsub1-sub1-add-user-div1 d-flex flex-column position-relative'>
            <label>Confirm Password</label>
            <Input.Password size='large' className='normal-input' value={formData.cfmpasswd} onChange={e => handleData(e, 'cfmpasswd')} autoComplete='new-cfmpasswd3' />
          </div>
        </div>
        <div className="subsub5-sub1-edit-user-div1 d-flex justify-content-center">
          <button onClick={() => handleSubmit()}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default EditMe;
