import React, { useEffect, useState } from 'react'
import { Checkbox, Input, Modal, notification } from 'antd';
import { updateRule } from '../../../services/axios';
import { RuleTypes } from '../../../common/common';

const _initValues = {
    name: "",
    type: "",
    alertText: "",
    isTeltonika: true,
    isRuptela: true,
    alertNotification: true,
    alertNotification2: true,
    alertEmail: false,
    alertSMS: false,
    threshold: "",
    threshold2: "",
    pinStatus: 0,
    pinStatus2: 0,
    pinStatus3: 0,
    pinStatus4: 0,
};

const BuiltinRuleEdit = ({ isShowModal, onClose, rule }) => {

    const [values, setValues] = useState(_initValues);

    useEffect(() => {
        if (rule) {
            setValues(rule);
        }
    }, [rule]);

    const handleValue = (field, value) => {
        setValues(prev => ({
            ...prev,
            [field]: value
        }));
    }

    const handleSubmit = async () => {
        let msg = null;
        if (values.name.length == 0) {
            msg = "Please input the Rule name";
        }
        if (msg != null) {
            notification.error({
                description: msg,
            });
            return;
        }

        let res = await updateRule(rule._id, values);
        if (res.status == 200) {
            notification.success({
                description: "Updated successfully!",
            });
            onClose(true);
        } else {
            notification.error({
                description: "Something went wrong!",
            });
        }
    }

    const renderOptions = () => {
        let options = null;
        switch (values.type) {
            case RuleTypes.DeviceStatus:
                options = <>
                    <Checkbox
                        checked={values.pinStatus}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus', e.target.checked)}
                    >
                        <span className='ms-4'>On Unplugged</span>
                    </Checkbox>
                    <Checkbox
                        checked={values.pinStatus2}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus2', e.target.checked)}
                    >
                        <span className='ms-4'>On Plugged</span>
                    </Checkbox>
                    <Checkbox
                        checked={values.pinStatus3}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus3', e.target.checked)}
                    >
                        <span className='ms-4'>Online</span>
                    </Checkbox>
                    <Checkbox
                        checked={values.pinStatus4}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus4', e.target.checked)}
                    >
                        <span className='ms-4'>Offline</span>
                    </Checkbox>
                </>;
                break;
            case RuleTypes.OverSpeed:
                options = <div className='mt-1'>
                    <label className='rule-option-label'>Speed Limit</label>
                    <Input
                        className='rule-option-value'
                        placeholder="Speed Limit"
                        size='large'
                        value={values.threshold}
                        onChange={(e) => handleValue('threshold', e.target.value)}
                        suffix="Km/Hr"
                    />
                </div>;
                break;
            case RuleTypes.Geofence:
                options = <>
                    <Checkbox
                        checked={values.pinStatus}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus', e.target.checked)}
                    >
                        <span className='ms-4'>On Entrance</span>
                    </Checkbox>
                    <Checkbox
                        checked={values.pinStatus2}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus2', e.target.checked)}
                    >
                        <span className='ms-4'>On Exit</span>
                    </Checkbox>
                </>;
                break;
            case RuleTypes.Temperature:
                options = <>
                    <div className='mt-1'>
                        <label className='rule-option-label'>Low Temperature</label>
                        <Input
                            className='rule-option-value type2'
                            placeholder="Low Temp Value"
                            size='large'
                            value={values.threshold}
                            onChange={(e) => handleValue('threshold', e.target.value)}
                            suffix="°C"
                        />
                    </div>
                    <div className=''>
                        <label className='rule-option-label'>High Temperature</label>
                        <Input
                            className='rule-option-value'
                            placeholder="High Temp Value"
                            size='large'
                            value={values.threshold2}
                            onChange={(e) => handleValue('threshold2', e.target.value)}
                            suffix="°C"
                        />
                    </div>
                </>;
                break;
            case RuleTypes.FuelStatus:
                options = <>
                    <div className='w-100 d-flex justify-content-between'>
                        <Checkbox
                            checked={values.pinStatus}
                            className='ms-1'
                            onChange={(e) => handleValue('pinStatus', e.target.checked)}
                        >
                            <span className='ms-1'>On Fuel Low</span>
                        </Checkbox>
                        <Input
                            className='rule-option-value'
                            placeholder='Fuel Low Value'
                            size='small' style={{ width: 120 }}
                            disabled={values.pinStatus != 1}
                            value={values.threshold}
                            onChange={e => handleValue('threshold', e.target.value)}
                            suffix="L"
                        />
                    </div>
                    <Checkbox
                        checked={values.pinStatus2}
                        className='ms-1'
                        onChange={(e) => handleValue('pinStatus2', e.target.checked)}
                    >
                        <span className='ms-2'>On Refuel</span>
                    </Checkbox>
                    <Checkbox
                        checked={values.pinStatus3}
                        className='ms-1'
                        onChange={(e) => handleValue('pinStatus3', e.target.checked)}
                    >
                        <span className='ms-2'>On Fuel Theft</span>
                    </Checkbox>
                </>;
                break;
            case RuleTypes.IgnitionStatus:
                options = <>
                    <Checkbox
                        checked={values.pinStatus}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus', e.target.checked)}
                    >
                        <span className='ms-4'>On Ignition ON</span>
                    </Checkbox>
                    <Checkbox
                        checked={values.pinStatus2}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus2', e.target.checked)}
                    >
                        <span className='ms-4'>On Ignition Low</span>
                    </Checkbox>
                </>;
                break;
            case RuleTypes.IgnitionControl:
                options = <>
                    <Checkbox
                        checked={values.pinStatus}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus', e.target.checked)}
                    >
                        <span className='ms-4'>On Ignition Enabled</span>
                    </Checkbox>
                    <Checkbox
                        checked={values.pinStatus2}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus2', e.target.checked)}
                    >
                        <span className='ms-4'>On Ignition Disabled</span>
                    </Checkbox>
                </>;
                break;
            case RuleTypes.DoorStatus:
                options = <>
                    <Checkbox
                        checked={values.pinStatus}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus', e.target.checked)}
                    >
                        <span className='ms-4'>On Door Opened</span>
                    </Checkbox>
                    <Checkbox
                        checked={values.pinStatus2}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus2', e.target.checked)}
                    >
                        <span className='ms-4'>On Door Closed</span>
                    </Checkbox>
                </>;
                break;
            case RuleTypes.DoorLockStatus:
                options = <>
                    <Checkbox
                        checked={values.pinStatus}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus', e.target.checked)}
                    >
                        <span className='ms-4'>On Door Unlocked</span>
                    </Checkbox>
                    <Checkbox
                        checked={values.pinStatus2}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus2', e.target.checked)}
                    >
                        <span className='ms-4'>On Door Locked</span>
                    </Checkbox>
                </>;
                break;
            case RuleTypes.BatteryLow:
                options = <div className='mt-1'>
                    <label className='rule-option-label'>Low Battery</label>
                    <Input
                        className='rule-option-value'
                        placeholder="Low Battery Value"
                        size='large'
                        value={values.threshold}
                        onChange={(e) => handleValue('threshold', e.target.value)}
                        suffix="%"
                    />
                </div>;
                break;
            case RuleTypes.GPSStatus:
                options = <>
                    <Checkbox
                        checked={values.pinStatus}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus', e.target.checked)}
                    >
                        <span className='ms-4'>On GPS Lost</span>
                    </Checkbox>
                    <Checkbox
                        checked={values.pinStatus2}
                        className='ms-3'
                        onChange={(e) => handleValue('pinStatus2', e.target.checked)}
                    >
                        <span className='ms-4'>On GPS Fixed</span>
                    </Checkbox>
                </>;
                break;
        }
        return options;
    }

    return (
        <Modal
            title={(
                <div className='position-relative text-center'>
                    <span className="fw-normal text-danger position-absolute top-0 start-0 mt-2 ms-4" style={{ textDecoration: "underline" }}>
                        Edit Rule
                    </span>
                    <span className='bg-danger text-white rounded-3 px-4 py-2' style={{ fontSize: "1.2rem" }}>
                        {rule?.name} {rule?.ioPin && "- " + rule?.ioPin}
                    </span>
                </div>
            )}
            centered
            open={isShowModal}
            onOk={() => onClose()}
            onCancel={() => onClose()}
            width={600}
            style={{ maxWidth: "100%" }}
            footer={false}
        >
            <div className='pt-4 rule-edit-modal'>
                <div className='row mb-5'>
                    <div className='col-6 d-flex flex-column gap-3 pe-5'>
                        <Input
                            className='text-center'
                            placeholder='Rule Name'
                            size='large'
                            value={values.name}
                            onChange={(e) => handleValue('name', e.target.value)}
                        />
                        {renderOptions()}
                    </div>
                    <div className='col-6'>
                        <div className='rounded-4 border border-light-subtle d-flex flex-column gap-3 py-3 px-2'>
                            <div className='ms-2'>Alert Notifications:</div>
                            <Checkbox
                                checked={values.alertNotification}
                                className='ms-3'
                                onChange={(e) => handleValue('alertNotification', e.target.checked)}
                            >
                                <span className='ms-4'>Notification (Web)</span>
                            </Checkbox>
                            <Checkbox
                                checked={values.alertNotification2}
                                className='ms-3'
                                onChange={(e) => handleValue('alertNotification2', e.target.checked)}
                            >
                                <span className='ms-4'>Notification (Mobile)</span>
                            </Checkbox>
                            <Checkbox
                                checked={values.alertEmail}
                                className='ms-3'
                                onChange={(e) => handleValue('alertEmail', e.target.checked)}
                            >
                                <span className='ms-4'>Email</span>
                            </Checkbox>
                            <Checkbox
                                checked={values.alertSMS}
                                className='ms-3'
                                onChange={(e) => handleValue('alertSMS', e.target.checked)}
                            >
                                <span className='ms-4'>SMS</span>
                            </Checkbox>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-3">
                    <button className="tab-button text-white" onClick={() => onClose()}>Cancel</button>
                    <button className="tab-button text-white" onClick={() => handleSubmit()}>Save</button>
                </div>
            </div>
        </Modal>
    )
}

export default BuiltinRuleEdit;
