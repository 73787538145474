import { Modal } from 'antd';
import { format, formatDistanceToNow } from 'date-fns'
import { DeviceModels } from './mockup';
import countries from "./output.json";

const ConfirmDialog = (props) => {
    try {
        if (window.confirm(props)) {
            return true;
        };
        return false;
    } catch (error) {
        return false;
    }
}
const formatDate = (props) => {
    // var jj = new Date(props);
    var jj = new Date();
    return jj.toLocaleDateString("en-GB") === "Invalid Date" ? "" : jj.toLocaleDateString("en-GB");
}

const formatTime = (props) => {
    // var jj = new Date(props);
    var jj = new Date();
    return jj.toLocaleTimeString() === "Invalid Date" ? "" : jj.toLocaleTimeString();
}

const formatDateTime = (date) => {
    if (date) {
        const dt = new Date(date);
        return format(dt, 'dd/MM/yyyy hh:mm aa');
    }
    return "";
}

const formatDateTimeSec = (date) => {
    if (date) {
        const dt = new Date(date);
        return format(dt, 'dd-MM-yyyy hh:mm:ss aa');
    }
    return "";
}

const formatDateTimeSec2 = (date) => {
    if (date) {
        const dt = new Date(date);
        return format(dt, 'HH:mm:ss dd/MM/yyyy');
    }
    return "";
}

const formatDateTimeFromNow = (date) => {
    if (date) {
        const dt = new Date(date);
        let str = formatDistanceToNow(dt, { includeSeconds: false });
        str = str.replace(" minutes", "min").replace(" minute", "min");
        str = str.replace(" hour", "hr");
        str = str.replace(" days", "D").replace(" day", "D");
        str = str.replace(" months", "M").replace(" month", "D");
        str = str.replace(" years", "Y").replace(" year", "Y");
        str = str + " Ago";
        return str;
    }
    return "";
}

const formatTimeFromSec = (seconds) => {
    let day = Math.floor(seconds / 86400)
    let hour = Math.floor((seconds % 86400) / 3600)
    let min = Math.floor(((seconds % 86400) % 3600) / 60)
    let sec = Math.floor(((seconds % 86400) % 3600) % 60);

    hour = day * 24 + hour;
    return (hour > 0 ? (hour + ":") : "") + ("0" + min).slice(-2) + ":" + ("0" + sec).slice(-2);
}

const truncate = (str, n) => {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
};

const showConfirm = (text) => {
    return new Promise((resolve, reject) => {
        Modal.confirm({
            title: "Confirm",
            content: text,
            centered: true,
            onOk: () => {
                resolve();
            }
        });
    });
}

const sleep = async (ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

const showConfirmEx = (text) => {
    return new Promise((resolve, reject) => {
        Modal.confirm({
            icon: null,
            title: null,
            content: text,
            centered: true,
            keyboard: false,
            width: 500,
            className: "techwelt-confirm",
            okText: 'NO',
            cancelText: 'YES',
            okButtonProps: {
                type: 'primary',
                className: "btn-no"
            },
            cancelButtonProps: {
                type: 'primary',
                className: "btn-yes"
            },
            onCancel: () => {
                resolve();
            },
            onOk: () => {
                reject();
            }
        });
    });
}

const showAlertEx = (title, text) => {
    Modal.info({
        title: title,
        content: (
            <div>
                <p>{text}</p>
            </div>
        ),
        onOk() { },
    });
}

const getDeviceImage = (model) => {
    const device = DeviceModels.find(item => item.device == model);
    if (device) {
        return device.path;
    }
    return "/assets/eng.jpeg";
}

const getGooglePosUrl = (lat, lng) => {
    let url = `http://www.google.com/maps/place/${lat},${lng}`;
    return url;
}

const getFuelValue = (ain) => {
    if (ain >= 5000) {
        return 40;
    }
    if (ain <= 1000) {
        return 0;
    }
    return Number((10 * (ain / 1000) - 10).toFixed(2));
}

const getAngleBetweenPoints = (lat1, long1, lat2, long2) => {
    try {
        let dLon = (long2 - long1);

        let y = Math.sin(dLon) * Math.cos(lat2);
        let x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

        let brng = Math.atan2(y, x);

        brng = brng * (180 / Math.PI);
        brng = (brng + 360) % 360;
        // brng = 360 - brng;

        return brng;
    } catch (err) {
        return 0;
    }
}

const getZoomLevel = (bounds, mapDim) => {

    var WORLD_DIM = { height: 256, width: 256 };
    var ZOOM_MAX = 21;

    function latRad(lat) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    var latFraction = (latRad(bounds[2]) - latRad(bounds[0])) / Math.PI;

    var lngDiff = bounds[3] - bounds[1];
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = zoom(mapDim?.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim?.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
};

const getMapZoomAndCenter = (positions, dimension) => {
    const bounds = positions.filter(
        position => position.lat != 0 && position.lng != 0
    ).reduce(
        (acc, position) => {
            return [
                Math.min(acc[0], position.lat),
                Math.min(acc[1], position.lng),
                Math.max(acc[2], position.lat),
                Math.max(acc[3], position.lng),
            ];
        },
        [
            Number.POSITIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Number.NEGATIVE_INFINITY,
            Number.NEGATIVE_INFINITY,
        ]
    );
    return {
        bounds: bounds,
        lat: (bounds[0] + bounds[2]) / 2,
        lng: (bounds[1] + bounds[3]) / 2,
        zoom: getZoomLevel(bounds, dimension)
    }
}

const getCountries = () => {
    return countries;
}



const getPacketStrValue = (packetStr, pos, length = -1) => {
    if (length == -1) {
        return packetStr.substring(pos * 2);
    }
    if (packetStr.length >= (pos + length) * 2) {
        return packetStr.substring(pos * 2, (pos + length) * 2);
    }
    return "";
}
const getPacketIntValue = (packetStr, pos, length) => {
    return parseInt(getPacketStrValue(packetStr, pos, length), 16);
}
const getPacketDateValue = (packetStr, pos, second = false) => {
    let year = getPacketIntValue(packetStr, pos, 1);
    let month = getPacketIntValue(packetStr, pos + 1, 1);
    let date = getPacketIntValue(packetStr, pos + 2, 1);
    let hour = getPacketIntValue(packetStr, pos + 3, 1);
    let min = getPacketIntValue(packetStr, pos + 4, 1);
    let sec = second ? getPacketIntValue(packetStr, pos + 5, 1) : 0;
    return new Date(2000 + year, month - 1, date, hour, min, sec);
}
const getPacketDateReverseValue = (packetStr, pos, second = false) => {
    let sec = second ? getPacketIntValue(packetStr, pos++, 1) : 0;
    let min = getPacketIntValue(packetStr, pos, 1);
    let hour = getPacketIntValue(packetStr, pos + 1, 1);
    let date = getPacketIntValue(packetStr, pos + 2, 1);
    let month = getPacketIntValue(packetStr, pos + 3, 1);
    let year = getPacketIntValue(packetStr, pos + 4, 1);
    return new Date(2000 + year, month - 1, date, hour, min, sec);
}

const getValueStr = (value, length, isNumber = true) => {
    if (length == 0) {
        return "";
    }
    let ret = isNumber ? Number(value).toString('16') : String(value);
    if (ret.length > length * 2) {
        return ret.slice(-1 * length * 2)
    } else {
        return ret.padStart(length * 2, '0');
    }
}
const getDateValueStr = (date) => {
    if (!date) {
        date = new Date();
    } else {
        date = new Date(date);
    }
    const result = [
        getValueStr(date.getFullYear() - 2000, 1),
        getValueStr(date.getMonth() + 1, 1),
        getValueStr(date.getDate(), 1),
        getValueStr(date.getHours(), 1),
        getValueStr(date.getMinutes(), 1),
    ];
    return result.join("");
}

const getStringFromBytes = (byteStr) => {
    let values = byteStr.match(/([a-fA-F0-9]{2})/g);
    if (values) {
        return String.fromCharCode(...values.map(x => parseInt(x, 16)));
    } else {
        return "";
    }
}
const getBytesFromString = (str, length) => {
    let asciiHexs = [];
    for (var i = 0; i < str.length; i++) {
        asciiHexs.push(getValueStr(str.charCodeAt(i), 1));
    }
    let ret = asciiHexs.join("");
    return ret.padEnd(length * 2, "00");
}

const getACSVerifySources = (value) => {
    let card = value & 1;
    let commonPassword = value & 2;
    let personalPassword = value & 4;
    let adminPassword = value & 8;
    let finger = value & 16;
    
    const results = [];
    if (card) results.push("Card");
    if (commonPassword) results.push("CP");
    if (personalPassword) results.push("PP");
    if (adminPassword) results.push("AP");
    if (finger) results.push("Finger");

    return results.join("/");
}


export {
    ConfirmDialog,
    formatDate,
    formatTime,
    formatDateTime,
    formatDateTimeSec,
    formatDateTimeSec2,
    formatDateTimeFromNow,
    formatTimeFromSec,

    sleep,
    truncate,
    showConfirm,
    showConfirmEx,
    showAlertEx,
    getDeviceImage,
    getGooglePosUrl,
    getAngleBetweenPoints,
    getZoomLevel,
    getMapZoomAndCenter,
    getCountries,
    getFuelValue,

    getPacketStrValue,
    getPacketIntValue,
    getPacketDateValue,
    getPacketDateReverseValue,
    getValueStr,
    getDateValueStr,
    getStringFromBytes,
    getBytesFromString,
    getACSVerifySources,
};
